import { SocialMediaBtns } from "@/components/SocialMediaBtns";
import { TeamList } from "@/components/TeamList";
import { SelectedTeamProvider } from "@/lib/context/selectedTeam";
import { Button, Center, useMantineTheme } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import clsx from "clsx";
import { useMemo } from "react";
import { FaHeart } from "react-icons/fa6";
import { Link } from "react-router-dom";

export const HomePage = () => {
  const theme = useMantineTheme();
  const { data: isOpen } = useQuery({
    queryKey: ["registrations", "open"],
    queryFn: async () => {
      const resp = await fetch("/api/registrations/open");
      if (!resp.ok) throw new Error("Failed to fetch if registrations are open");
      const data = await resp.text();
      return data == "true";
    }
  });
  const bgNum = useMemo(() => Math.floor(Math.random() * 3) + 1, []);

  return (
    <div>
      <div className="hero-image-1 hero-image-2 hero-image-3" />
      <section
        className={clsx(
          "flex min-h-[100vh] snap-start flex-col items-center gap-4 bg-cover bg-center text-center font-gothic text-white",
          bgNum && `hero-image-${bgNum}`
        )}
      >
        <a href="https://studentkickoff.be" className="mt-28 w-28">
          <img
            src="/images/logo.png"
            alt="Student Kick-Off logo"
            className="object-fit h-full w-full"
          />
        </a>
        <p className="text-shadow text-7xl font-semibold uppercase">Festival zkt studenten!</p>
        <p className="text-shadow text-3xl">
          Wil jij deel uitmaken van het grootste een-dag festival van België?
        </p>
        <p className="text-shadow text-2xl">{isOpen ? "Join de crew voor 2025!" : "De sollicitaties zijn gesloten voor deze editie! Hoe onze socials in de hoogte wanneer deze open gaan"}</p>
        <p className="text-shadow text-2xl">
          De crew van Student Kick-Off werkt op vrijwillige basis
        </p>
        <Button
          disabled={!isOpen}
          style={{ fontFamily: theme.fontFamily }}
          className="shadow-xl"
          variant="white"
          component={Link}
          to="/join-us"
          size="lg"
        >
          Join us!
        </Button>
      </section>
      <section className="snap-start">
        {!isOpen && (
          <p className="text-center font-semibold text-2xl py-2 animate-pulse">De sollicitaties zijn momenteel gesloten! Hou onze socials in de hoogte voor wanneer deze terug open gaan!</p>
        )}
        <SelectedTeamProvider>
          <TeamList disableRankSelection />
        </SelectedTeamProvider>
        {isOpen && (
          <Center className="pt-2">
            <Button component={Link} to="/join-us" size="lg">
              Join us!
            </Button>
          </Center>
        )}
      </section>
      <footer className="mt-2 w-full">
        <SocialMediaBtns />
        <div className="mt-2 flex items-center justify-center text-gray-500 underline">
          <Link to="/login">Login</Link>
        </div>
        <p className="mt-2 flex items-baseline justify-center gap-1 text-gray-500">
          Made with{" "}
          <span>
            <FaHeart size="1rem" className="text-primary/80" />
          </span>{" "}
          by Team IT
        </p>
      </footer>
    </div>
  );
};
