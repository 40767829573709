import { Team } from "@/lib/types";
import { useQuery } from "@tanstack/react-query";
import { ErrorView } from "./query/error";
import { LoadingSpinner } from "./query/loading";
import { TeamCard } from "./TeamCard";
import { useSelectedTeam } from "@/lib/context/selectedTeam";
import { FunctionList } from "./FunctionList";
import { SollicitationRanking } from "./ranking/SollicitationRanking";
import { Fragment } from "react/jsx-runtime";
import { useRef } from "react";

export const TeamList = ({ disableRankSelection }: { disableRankSelection?: boolean }) => {
  const { selectedTeam } = useSelectedTeam();
  const {
    data: teams,
    isLoading,
    isError,
    error
  } = useQuery<Team[]>({
    queryKey: ["teams", "functions"],
    queryFn: async () => {
      const resp = await fetch("/api/teams");
      if (!resp.ok) {
        throw new Error("Failed to fetch teams");
      }
      return resp.json();
    }
  });
  const functionRef = useRef<HTMLDivElement | null>(null);

  if (isError) {
    return <ErrorView error={error} />;
  }

  if (isLoading || !teams) {
    return <LoadingSpinner thing="teams" />;
  }

  return (
    <div className="mx-auto flex justify-between gap-4 px-2 py-4">
      <div className="flex w-full flex-col items-center gap-4">
        {!disableRankSelection && (
          <div className="block w-full overflow-hidden rounded-xl border p-4 shadow md:hidden">
            <SollicitationRanking />
          </div>
        )}
        {teams.map((t) => (
          <Fragment key={t.id}>
            <TeamCard
              team={t}
              disableRankSelection={disableRankSelection}
              functionRef={functionRef}
            />
            {selectedTeam && selectedTeam.id === t.id && !disableRankSelection && (
              <div className="block md:hidden">
                <FunctionList disableRankSelection={disableRankSelection} />
              </div>
            )}
          </Fragment>
        ))}
      </div>
      <div className="hidden h-full w-full flex-col gap-2 md:flex" ref={functionRef}>
        {selectedTeam ? (
          <>
            {!disableRankSelection && (
              <div className="overflow-hidden rounded-xl border p-4 shadow">
                <SollicitationRanking />
              </div>
            )}
            <FunctionList disableRankSelection={disableRankSelection} />
          </>
        ) : (
          <p className="text-center text-2xl font-semibold">
            Selecteer een team om de functies te zien!
          </p>
        )}
      </div>
    </div>
  );
};
